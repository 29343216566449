import type { Settings, Translation } from 'types';

import React, { createContext, useContext, useMemo } from 'react';

export type WordpressContextTranslations = {
  pageTranslations?: Translation[];
};

export type WordpressContext = Omit<
  Settings,
  'error' | 'newsCategory' | 'mustReadTag'
> &
  WordpressContextTranslations;

export const DEFAULT_CONTEXT_VALUES: WordpressContext = {
  app: null,
  authorsPageRoundedSection: null,
  blogPage: null,
  blogMedicalCardCta: null,
  campaigns: null,
  cannabisGuidanceCta: null,
  cardPage: null,
  cityGuides: [],
  contactsPage: null,
  conditionsPage: null,
  conditionTypesImage: null,
  contacts: null,
  copyright: null,
  disableMedicalCard: false,
  footerMenu: null,
  headerMenu: null,
  languageAlternates: null,
  lawsPage: null,
  logo: null,
  newsletterForm: null,
  openStates: [],
  pageTranslations: null,
  partnerMapPage: null,
  renewalPages: [],
  reviewsSettings: null,
  seoSettings: null,
  siteTitle: null,
  social: null,
  statePages: [],
  strainsArchivePage: null,
  strainsLandingPage: null,
  topStates: null,
  translations: null,
  trustpilotNumberReviews: null,
  trustpilotRating: null,
};

export const WPContext = createContext<WordpressContext>(
  DEFAULT_CONTEXT_VALUES,
);

export function useWordPressContext(): WordpressContext {
  return useContext(WPContext);
}

export default function WordPressProvider({
  children,
  value,
}: {
  children: React.ReactElement;
  value: WordpressContext;
}): JSX.Element {
  const contextValue = useMemo(() => {
    const transformedData = {};

    for (const key in DEFAULT_CONTEXT_VALUES) {
      transformedData[key] = value[key] || DEFAULT_CONTEXT_VALUES[key];
    }

    return transformedData as WordpressContext;
  }, [value]);

  return (
    <WPContext.Provider value={contextValue}>{children}</WPContext.Provider>
  );
}

import type { Webmaster } from 'types';

import { useMemo } from 'react';
import Head from 'next/head';

const VerificationMeta: React.FC<Webmaster> = props => {
  const metaValues = useMemo(() => {
    return [
      { name: 'baidu-site-verification', content: props?.baiduVerification },
      { name: 'msvalidate.01', content: props?.bingVerification },
      {
        name: 'google-site-verification',
        content: props?.googleVerification,
      },
      {
        name: 'p:domain_verify',
        content: props?.pinterestVerification,
      },
      { name: 'yandex-verification', content: props?.yandexVerification },
    ];
  }, [props]);

  return (
    <>
      {metaValues.map(
        ({ name, content }) =>
          content && <meta name={name} content={content} key={name} />,
      )}
    </>
  );
};

const AppHead: React.FC<{ webmasterMeta?: Webmaster }> = ({
  webmasterMeta,
}) => {
  return (
    <Head>
      {webmasterMeta && <VerificationMeta {...webmasterMeta} />}
      <meta httpEquiv="x-ua-compatible" content="ie=edge" key="httpequiv" />
      <meta
        name="viewport"
        content="width=device-width, viewport-fit=cover, initial-scale=1.0, maximum-scale=3.0"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        color="#36DC90"
      />
      <link rel="shortcut icon" href="/favicon/favicon.ico" />
      <meta name="theme-color" content="#ffffff" key="theme-color" />
      {process.env.NODE_ENV === 'production' ? (
        <script
          id="reddit-ad-script"
          dangerouslySetInnerHTML={{
            __html: `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','t2_uxq3xpdi');rdt('track', 'PageVisit');`,
          }}
        />
      ) : null}
      {process.env.NODE_ENV === 'production' ? (
        <>
          <script
            id="event-sitewide-pixel-vars"
            dangerouslySetInnerHTML={{
              __html: `window.JCRevenue=null;
              window.JCOrderID=null;
              window.JCProductList=null;
              window.JCClientUserIDList=null;`,
            }}
          />
          <script
            id="event-sitewide-pixel-func"
            dangerouslySetInnerHTML={{
              __html: `(function(){window.globalUrl='https://app.jointcommerce.com/api/event-data/610/';fetch(window.globalUrl,{method:'GET',mode:'cors',credentials:'omit',headers:{'Accept':'text/html'}}).then(function(response){if(!response.ok)throw new Error('Network response was not ok');return response.text();}).then(function(data){var parser=new DOMParser();var parsedHTML=parser.parseFromString(data,'text/html');var scripts=parsedHTML.getElementsByTagName('script');for(var i=0;i<scripts.length;i++){var script=document.createElement('script');script.async=true;if(scripts[i].src){script.src=scripts[i].src;}else{script.textContent=scripts[i].textContent;}
              document.body.appendChild(script);}}).catch(function(error){console.error('There was a problem with the fetch operation:',error);});})();`,
            }}
          />
        </>
      ) : null}
    </Head>
  );
};

export default AppHead;
